import React, { Component, Fragment } from 'react';
import { Segment, Grid, Table, Pagination, Header, Icon, Label, Button, Statistic, Image, Divider, Loader, Popup } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
import Configuration from '../config/Configuration.js';
import AuthenticationService from '../api/AuthenticationService.js';
import { authHeaderSet, authHeader, authHeaderPatch } from '../helpers/auth-header.js';
import { withTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import symbol from '../images/zipster-symbol-orange.png';

class UserOverview extends Component {

    constructor() {
        super();
        this.config = new Configuration();
        this.authenticationService = new AuthenticationService();
        this.state = {
            activeUser: this.authenticationService.getActiveUser(),
            activePage: 0,
            organizationRequestPageSize: 100,
            organizationsPageSize: 100,
            totalPages: 1,
            totalElements: 1,
            pageSize: 8,
            organizations: [],
            nrOfOrganizations: null,
            nrOfServices: 0,
            practitionerRoles: [],
            organizationName: "",
            selectedOrganization: null,
            allPractitioners: [],
            inactivePractitioners: [],
            organizationRequests: [],
            activePractitioners: 0,
            organizationView: true
        }
    }

    retrieveOrganizationRequests = () => {
        return fetch(this.config.REGISTER_URL + "?pagesize=" + this.state.organizationRequestPageSize, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(organizationRequests => {
                let requests = organizationRequests._embedded.organizationRequests;
                this.setState({
                    organizationRequests: requests
                });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrievePractitioners = (organizationId, all) => {
        return fetch(this.config.PRACTITIONERROLES_URL + "?organization=" + organizationId + "&pagesize=" + this.state.pageSize, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(practitionerRoles => {
                let roles = practitionerRoles._embedded.practitionerRoles;
                let inactive = [];
                let counter = 0;
                for (var i = 0; i < roles.length; i++) {
                    let role = roles[i];
                    if (role.status === 'Active') {
                        counter++;
                    } else {
                        inactive.push(role);
                    }
                }
                let allPractitioners = this.state.allPractitioners.concat(roles);
                let activePractitionerCount = this.state.activePractitioners + counter;
                this.setState({
                    practitionerRoles: roles
                });
                if (all) {
                    this.setState({
                        inactivePractitioners: [...this.state.inactivePractitioners, ...inactive],
                        allPractitioners: allPractitioners,
                        activePractitioners: activePractitionerCount
                    });
                }
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveHealthcareServices = (organizationId) => {
        let query = '?provider=' + organizationId;
        return fetch(this.config.HEALTHCARESERVICES_URL + query, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(services => {
                let totalElements = services._page.totalElements;
                this.setState({
                    nrOfServices: this.state.nrOfServices + totalElements,
                });
                return services._embedded.healthcareServices;
            }).catch(error => {
                this.handleError(error);
            });
    }

    retrieveOrganizations = () => {
        let query = '';
        if (this.state.organizationName && (this.state.organizationName.length > 1)) {
            query += "&name=" + this.state.organizationName;
        }
        if (this.state.searchLocationId && this.state.searchLocationId !== this.config.REGION_VLAANDEREN_ID) {
            query += "&location=" + this.state.searchLocationId;
        }
    
        return fetch(this.config.ORGANIZATIONS_URL + "?pagesize=" + this.state.organizationsPageSize + "&page=" + this.state.activePage + query, {
            method: 'GET',
            headers: authHeaderSet()
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            if (!data._embedded || !data._embedded.organizations) {
                console.error("No organizations found in response");
                return;
            }
            
            let organizations = data._embedded.organizations;
            console.log("Retrieved Organizations:", organizations);
    
            this.setState({
                totalPages: data._page.totalPages,
                totalElements: data._page.totalElements,
                organizations: organizations,
                nrOfOrganizations: data._page.totalElements,
                inactivePractitioners: []
            }, () => {
                console.log("State Updated. Organizations in state:", this.state.organizations);
    
                // Iterate over the organizations **after** state update
                this.state.organizations.forEach((organization) => {
                    console.log("Fetching services and practitioners for:", organization.uuid);
                    this.retrieveHealthcareServices(organization.uuid);
                    this.retrievePractitioners(organization.uuid, true);
                });
            });
        })
        .catch(error => {
            console.error("Error fetching organizations:", error);
        });
    };    

    retrieveELZForLocation = (locationId) => {
        return fetch(this.config.LOCATIONS_URL + "?childId=" + locationId + "&level=Eerstelijnszone", {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(locations => {
                let locationz = locations._embedded.locations;
                let location = locationz[0];
                this.setState({
                    searchLocationId: location.uuid,
                    searchLocationName: location.name,
                    elzId: location.uuid,
                    elzName: location.name
                });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveLocationForPostalCode = (postalCode) => {
        return fetch(this.config.LOCATIONS_URL + "?code=" + postalCode, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(locations => {
                let locationz = locations._embedded.locations;
                let location = locationz[0];
                this.retrieveELZForLocation(location.uuid);
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveAddress = (addressUuid) => {
        return fetch(this.config.ADDRESSES_URL + "/" + addressUuid, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(address => {
                let addressString = address.lines + ", " + address.postalCode + " " + address.city;
                this.setState({
                    organizationAddress: addressString
                });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveAddressAndElz = (addressUuid) => {
        return fetch(this.config.ADDRESSES_URL + "/" + addressUuid, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(address => {
                this.retrieveLocationForPostalCode(address.postalCode);
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveOrganization() {
        return fetch(this.config.ORGANIZATIONS_URL + "/" + this.state.organizationId, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(organization => {
                this.retrieveAddressAndElz(organization.address.uuid);
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    approve = (requestId) => {
        return fetch(this.config.REGISTER_URL + "/" + requestId, {
            method: 'PATCH',
            headers: authHeaderPatch(),
            body: JSON.stringify({
                status: 'Approved'
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                this.retrieveOrganizationRequests();
                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    reject = (requestId) => {
        return fetch(this.config.REGISTER_URL + "/" + requestId, {
            method: 'PATCH',
            headers: authHeaderPatch(),
            body: JSON.stringify({
                status: 'Rejected'
            })
        }).then(response => {
            if (!response.ok) {
                this.handleResponseError(response);
            } else {
                this.retrieveOrganizationRequests();
                return response.json();
            }
        }).catch(error => {
            this.handleError(error);
        });
    }

    componentDidMount() {
        this.setState({
            organizationId: this.state.activeUser.organization.uuid
        },
            () => {
                this.retrieveOrganization();
            });
        this.retrieveOrganizationRequests();
    }

    componentDidUpdate() {
        if (this.state.searchLocationId && this.state.organizations.length === 0) {
            this.retrieveOrganizations();
        }
    }

    handleResponseError(response) {
        return {
            error: true,
            status: response.status
        };
    }

    handleError(error) {
        console.log(error.message);
    }

    loadEmployees = (organization) => {
        this.setState({
            selectedOrganization: organization
        });
        this.retrieveAddress(organization.address.uuid);
        this.retrievePractitioners(organization.uuid, false);
    }

    showOrganizations = () => {
        return <Fragment>
            {this.state.organizations.slice(this.state.activePage * this.state.pageSize, (this.state.activePage + 1) * this.state.pageSize).map((organization, index) =>
                <Table.Row>
                    <Table.Cell>{organization.name}</Table.Cell>
                    <Table.Cell textAlign='center'></Table.Cell>
                    <Table.Cell><Button as={Link} circular icon='eye' size='mini' color='orange' onClick={this.loadEmployees.bind(this, organization)} /></Table.Cell>
                </Table.Row>
            )}
        </Fragment>
    }

    getStatus = (pratictionerRole) => {
        if (pratictionerRole.status === 'Active') {
            return <Icon name='check circle' color='green'/>;
        } else if (pratictionerRole.status === 'InActive') {
            return <Icon name='remove circle' color='grey' />;
        } else {
            return <Icon name='minus circle' color='red' />;
        }
    }

    getAdminLabel = (practitionerRole) => {
        const { t } = this.props;
        if (practitionerRole.authorizationLevel === 'ADMIN') {
            return <Fragment>&nbsp;<Label size='mini' horizontal color='blue'>{t("Administrator")}</Label></Fragment>;
        }
        return;
    }    
    
    getZipsterModusLabel = (practitionerRole) => {
        if (!practitionerRole.isObserver) {
            return <Fragment>&nbsp;<Icon name='hand point right' color='orange'/></Fragment>;
        } else {
            if (this.state.selectedOrganization.type.includes("Hybrid")) {
                return <Fragment>&nbsp;<Icon name='handshake' color='blue'/>&nbsp;<Icon name='hand point right' color='orange'/></Fragment>;
            } else {
                return <Fragment>&nbsp;<Icon name='handshake' color='blue'/></Fragment>;
            }
        }
    }

    showEmployees = () => {
        const { t } = this.props;
        if (this.state.selectedOrganization) {
            return <Fragment>
                <Header textAlign='center' size='large' color='blue' block attached='top'>{this.state.selectedOrganization.name}</Header>
                <Segment attached>
                    <Header textAlign='center' size='large' color='blue' block attached='top'>Over de Organisatie</Header>
                    <Segment attached>
                        <p><Icon name='map marker' color='blue' />{this.state.organizationAddress}</p>
                        <p><Icon name='phone' color='blue' />{this.state.selectedOrganization.phone}</p>
                        <p><Icon name='mail' color='blue' />{this.state.selectedOrganization.email}</p>
                        <p><Icon name='globe' color='blue' /><a href={this.state.selectedOrganization.website}>{this.state.selectedOrganization.website}</a></p>
                    </Segment>
                    <Header textAlign='center' size='large' color='blue' block attached='top'>Gebruikers</Header>
                    <Segment attached>
                        <Table selectable striped unstackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{t("Gebruiker")}</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center'>{t("Rol")}</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center'>{t("Geactiveerd")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.practitionerRoles.slice(0, this.state.practitionerRoles.length).map((practitionerRole, index) =>
                                    <Table.Row>
                                        <Table.Cell>{practitionerRole.name} {this.getAdminLabel(practitionerRole)} {this.getZipsterModusLabel(practitionerRole)}</Table.Cell>
                                        <Table.Cell textAlign='center'>{practitionerRole.speciality}</Table.Cell>
                                        <Table.Cell textAlign='center'>{this.getStatus(practitionerRole)}</Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    </Segment>
                </Segment>
            </Fragment>;
        }
    }

    handlePaginationChange = (e, { activePage }) => {
        this.setState({
            activePage: activePage
        })
    }

    handleOrganizationNameChange = (e, { name, value }) => { this.setState({ [name]: value }); this.retrieveOrganizations() }

    displayNumber = (number) => {
        if (number) {
            return number;
        } else {
            return <Loader active size='large' />;
        }
    }

    switchViewingMode = () => {
        if (this.state.organizationView) {
            this.setState({
                organizationView: false
            })
        } else {
            this.setState({
                organizationView: true
            })
        }
    }

    getViewButton = () => {
        if (this.state.organizationView) {
            return <Button as={Link} icon color='blue' floated='right' labelPosition='right' onClick={this.switchViewingMode}><Icon name='user cancel' />Toon Niet-geactiveerde Gebruikers</Button>;
        } else {
            return <Button as={Link} icon color='blue' floated='right' labelPosition='right' onClick={this.switchViewingMode}><Icon name='building' />Toon Organisaties</Button>;
        }
    }

    getUserOverview = () => {
        const { t } = this.props;
        const {
            activePage
        } = this.state;
        if (this.state.organizationView) {
            return <Fragment>
                <Header textAlign='center' size='large' color='blue' block attached='top'>Organisaties</Header>
                <Segment attached>
                    <Grid columns={2} width='equal'>
                        <Grid.Column>
                            <Table selectable striped unstackable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{t("Organisation")}</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'></Table.HeaderCell>
                                        <Table.HeaderCell />
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.showOrganizations()}
                                </Table.Body>
                            </Table>
                            <Segment basic textAlign='center'>
                                <Pagination
                                    activePage={activePage}
                                    onPageChange={this.handlePaginationChange}
                                    size='mini'
                                    totalPages={Math.floor(this.state.nrOfOrganizations / this.state.pageSize)} />
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            {this.showEmployees()}
                        </Grid.Column>
                    </Grid>
                </Segment>
            </Fragment>;
        } else {
            return <Fragment>
                <Header textAlign='center' size='large' color='blue' block attached='top'>Niet-geactiveerde Gebruikers</Header>
                <Segment attached>
                    <Table selectable striped unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t("Gebruiker")}</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>{t("Organisatie")}</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>{t("Rol")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.inactivePractitioners.slice(0, this.state.inactivePractitioners.length).map((practitionerRole, index) =>
                                <Table.Row>
                                    <Table.Cell>{practitionerRole.name} {this.getAdminLabel(practitionerRole)}</Table.Cell>
                                    <Table.Cell textAlign='center'>{practitionerRole.organization.name}</Table.Cell>
                                    <Table.Cell textAlign='center'>{practitionerRole.speciality}</Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </Segment>
            </Fragment>;
        }
    }

    getApprovalButton = (request) => {
        if (request.status === 'Pending') {
            return <Button as={Link} circular icon='thumbs up' size='mini' color='blue' onClick={this.approve.bind(this, request.uuid)} />;
        } else if (request.status === 'Executed') {
            let approvalMessage = 'Goedgekeurd door ' + request.handledBy.name;
            return <Popup content={approvalMessage} trigger={<Button circular icon='thumbs up' size='mini' />} />;
        }
    }

    getRejectionButton = (request) => {
        if (request.status === 'Pending') {
            return <Button as={Link} circular icon='thumbs down' size='mini' color='orange' onClick={this.reject.bind(this, request.uuid)} />;
        } else if (request.status === 'Rejected') {
            let rejectionMessage = 'Afgewezen door ' + request.handledBy.name;
            return <Popup content={rejectionMessage} trigger={<Button circular icon='thumbs down' size='mini' />} />;
        }
    }

    getOrganizationRequests = () => {
        const { t } = this.props;
        if (this.state.organizationRequests && this.state.organizationRequests.length > 0) {
            return <Fragment>
                <Header textAlign='center' size='large' color='blue' block attached='top'>Nieuwe Aanvragen</Header>
                <Segment attached>
                    <Table selectable striped unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t("Name Organisation")}</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>{t("City")}</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>{t("Postal Code")}</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>{t("Street and house number")}</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>{t("Given Name Administrator")}</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>{t("Family Name Administrator")}</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>{t("Email Name Administrator")}</Table.HeaderCell>
                                <Table.HeaderCell />
                                <Table.HeaderCell />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.organizationRequests.slice(0, this.state.organizationRequests.length).map((request, index) =>
                                <Table.Row>
                                    <Table.Cell>{request.organizationName}</Table.Cell>
                                    <Table.Cell textAlign='center'>{request.city}</Table.Cell>
                                    <Table.Cell textAlign='center'>{request.postalCode}</Table.Cell>
                                    <Table.Cell textAlign='center'>{request.streetAndNumber}</Table.Cell>
                                    <Table.Cell textAlign='center'>{request.givenName}</Table.Cell>
                                    <Table.Cell textAlign='center'>{request.familyName}</Table.Cell>
                                    <Table.Cell textAlign='center'>{request.email}</Table.Cell>
                                    <Table.Cell>{this.getApprovalButton(request)}</Table.Cell>
                                    <Table.Cell>{this.getRejectionButton(request)}</Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </Segment>
            </Fragment>;
        }
    }

    exportUsersToExcel = () => {
        const { allPractitioners } = this.state;
    
        if (allPractitioners.length === 0) {
            alert("No users to export.");
            return;
        }
    
        const data = allPractitioners.map(user => ({
            "Naam": user.name,
            "Organisatie": user.organization ? user.organization.name : "N/A",
            "Rol": user.speciality || "N/A",
            "E-mail": user.email || "N/A",
            "Accountstatus": user.status
        }));
    
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
    
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const dataBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    
        saveAs(dataBlob, "ZipsterUsers.xlsx");
    };

    getExportButton = () => {
        return (
            <Button color='blue' icon labelPosition='right' floated='left' onClick={this.exportUsersToExcel}>
                <Icon name='file excel' /> Exporteer Gebruikers
            </Button>
        );
    };

    render() {
        return <Segment>
            <Header size='huge' textAlign='center'>
                <Image size='small' circular src={symbol} />Gebruikers {this.state.elzName}</Header>
            <Divider />
            <Segment textAlign='center'>
                <Grid columns={2} stretched>
                    <Grid.Column>
                        <Segment basic textAlign='center'>
                            <Statistic color='blue' size='tiny'>
                                <Statistic.Value>{this.state.nrOfOrganizations}</Statistic.Value>
                                <Statistic.Label>Organisaties</Statistic.Label>
                            </Statistic>
                        </Segment>
                        <Segment basic textAlign='center'>
                            <Statistic color='blue' size='tiny'>
                                <Statistic.Value>{this.state.nrOfServices}</Statistic.Value>
                                <Statistic.Label>Diensten</Statistic.Label>
                            </Statistic>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment basic textAlign='center'>
                            <Statistic color='blue' size='tiny'>
                                <Statistic.Value>{this.state.allPractitioners.length}</Statistic.Value>
                                <Statistic.Label>Gebruikers</Statistic.Label>
                            </Statistic>
                        </Segment>
                        <Segment basic textAlign='center'>
                            <Statistic color='blue' size='tiny'>
                                <Statistic.Value>{this.state.activePractitioners}</Statistic.Value>
                                <Statistic.Label>Geactiveerde Gebruikers</Statistic.Label>
                            </Statistic>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Segment>
            {this.getOrganizationRequests()}
            <Segment basic>
            {this.getExportButton()}{this.getViewButton()}</Segment>
            <Divider hidden/>
            {this.getUserOverview()}
        </Segment>;
    }

}

function WithNavigateWrapper(props) {
    const navigate = useNavigate();
    return <UserOverview {...props} navigate={navigate} />;
}

export default withTranslation()(WithNavigateWrapper);
